import store from '@/store'
import { getModule, Module, VuexModule } from 'vuex-module-decorators'

export const ErrorMsg = {
  responseError: (status: number) => 'API response error status:' + status,
} as const

export const TAX_RATE = 0.1

export const Sbp = {
  CampType: {
    WITH_PAYMENT_THIS_MONTH: 0,
    WITHOUT_PAYMENT_THIS_MONTH: 1,
  },
} as const

@Module({ dynamic: true, namespaced: true, name: 'purchase', store })
class Mod extends VuexModule {}

export default getModule(Mod)
