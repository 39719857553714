import originalDayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import ja from 'dayjs/locale/ja'

originalDayjs.extend(utc)
originalDayjs.extend(timezone)
originalDayjs.locale(ja)
originalDayjs.tz.setDefault('Asia/Tokyo')

/**
 * 下記のいずれかの形式であることを確認する正規表現
 * 年: YYYY
 * 年月: YYYYMM, YYYY-MM, YYYY/MM
 * 年月日: YYYYMMDD, YYYY-MMDD, YYYY/MMDD, YYYYMM-DD, YYYYMM/DD, YYYY-MM-DD, YYYY-MM/DD, YYYY/MM-DD, YYYY/MM/DD
 */
const REGEX_DATE = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})$/

const dayjs = function (date: string | Date | Dayjs = originalDayjs()): Dayjs {
  if (typeof date === 'string' && REGEX_DATE.test(date)) {
    return originalDayjs.tz(date)
  }

  return originalDayjs(date).tz()
}

export default dayjs
export { Dayjs }
