























import { Contract, ContractType } from '@/store/Contracts'
import { TAX_RATE } from '@/store/Purchase'
import dayjs from '@/libs/dayjs'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class extends Vue {
  @Prop({ type: Object }) contract!: Contract

  get isTrial(): boolean {
    return this.contract?.plan?.price === 0
  }

  get isCredit(): boolean {
    return this.contract?.type === ContractType.CREDIT
  }

  get isNegotiation(): boolean {
    return this.contract?.type === ContractType.NEGOTIATION
  }

  dateFormat(dateStr?: string): string {
    return dateStr ? dayjs(dateStr).format('YYYY/MM/DD') : ''
  }

  get startDate(): string {
    return this.dateFormat(this.contract?.startDate)
  }

  get endDate(): string {
    return this.dateFormat(this.contract?.endDate)
  }

  get maxGroups(): number {
    return this.contract?.plan?.maxGroups ?? 0
  }

  get tax(): number {
    return this.taxExcludedPrice * TAX_RATE
  }

  get taxIncludedPrice(): number {
    return this.taxExcludedPrice + this.tax
  }

  get taxExcludedPrice(): number {
    return this.contract?.plan?.price ?? 0
  }
}
