











































import contractStore, { Contract, ContractType } from '@/store/Contracts'
import ContractItem from '@/components/organisms/Contract/ContractItem.vue'
import ChangeRestictModal from '@/components/organisms/Contract/ChangeRestictModal.vue'
import CancelRestictModal from '@/components/organisms/Contract/CancelRestictModal.vue'
import dayjs from '@/libs/dayjs'
import VABox from 'va/widgets/VABox.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({ components: { VABox, ContractItem, ChangeRestictModal, CancelRestictModal } })
export default class extends Vue {
  get latestContract(): Contract | null {
    return contractStore.latestContractForNoContract
  }

  get futureContract(): Contract | null {
    return contractStore.futureContractForNoContract
  }

  get latestExists(): boolean {
    return this.latestContract !== null && Object.keys(this.latestContract).length > 0
  }

  get futureExists(): boolean {
    return this.futureContract !== null && Object.keys(this.futureContract).length > 0
  }

  get isLatestCredit(): boolean {
    return this.latestContract?.type === ContractType.CREDIT
  }

  get isFutureCredit(): boolean {
    return this.futureContract?.type === ContractType.CREDIT
  }

  get latestEndsAtThisMonth(): boolean {
    return this.latestContract !== null && dayjs(this.latestContract.endDate).isSame(dayjs(), 'months')
  }

  get isLatestEndDateUndecided(): boolean {
    return this.latestContract !== null && this.latestContract.endDate === null
  }

  get isLatestTrial(): boolean {
    return this.latestContract?.plan?.price === 0
  }

  get canCreate(): boolean {
    if (!this.futureExists) {
      if (!this.latestExists) {
        return true
      }
      if (this.isLatestTrial) {
        return true
      }
      if (this.latestEndsAtThisMonth) {
        return true
      }
    }

    return false
  }

  get canChange(): boolean {
    if (this.latestExists && this.isFutureCredit) {
      return true
    }
    if (this.isLatestCredit && this.isLatestEndDateUndecided && !this.futureExists) {
      return true
    }

    return false
  }

  get canCancel(): boolean {
    if (this.latestExists && this.isFutureCredit) {
      return true
    }
    if (this.isLatestCredit && this.isLatestEndDateUndecided && !this.futureExists) {
      return true
    }

    return false
  }

  createContract(): void {
    this.$router.push({ name: 'PurchaseContractCreate' })
  }

  changeContract(): void {
    this.$router.push({ name: 'PurchaseContractChangeSelectPlan' })
  }

  cancelContract(): void {
    this.$router.push({ name: 'PurchaseContractCancelRequest' })
  }

  issueReceipt(): void {
    this.$router.push({ name: 'ContractReceipt' })
  }
}
