




































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class extends Vue {
  @Prop({ default: '' }) title!: string
  @Prop({ default: '540' }) size!: string
  // モーダル処理
  $fixApp!: (fixed: boolean) => void
  isOpen = false
  modalClose(): void {
    this.isOpen = false
    this.$fixApp(false)
  }
  modalOpen(): void {
    this.isOpen = true
    this.$fixApp(true)
  }
}
