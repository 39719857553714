

















import Modal from '@/components/molecules/Modal.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({ components: { Modal } })
export default class extends Vue {
  mounted(): void {
    if (localStorage.getItem('firstTimeFlag')) {
      localStorage.removeItem('firstTimeFlag')
      this.$nextTick(() => this.modalOpen())
    }
  }
  modalOpen(): void {
    const modal = this.$refs.modal as Modal
    modal.modalOpen()
  }
  modalClose(): void {
    const modal = this.$refs.modal as Modal
    modal.modalClose()
  }
}
