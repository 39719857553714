
















import Modal from '@/components/molecules/Modal.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({ components: { Modal } })
export default class extends Vue {
  modalClose(): void {
    const modal = this.$refs.modal as Modal
    modal.modalClose()
  }
}
