

































import ContractInfo from '@/components/organisms/Contract/ContractInfo.vue'
import contractStore from '@/store/Contracts'
import menuStore from '@/store/Menu'
import { Component, Vue } from 'vue-property-decorator'
import TutorialModal from '@/components/organisms/Layout/TutorialModal.vue'

@Component({
  components: { ContractInfo, TutorialModal },
})
export default class extends Vue {
  async mounted(): Promise<void> {
    // 初期化
    menuStore.setActiveFlg({ settings: true })
    await Promise.all([
      contractStore.fetchLatestContractForNoContract(),
      contractStore.fetchFutureContractForNoContract(),
    ])
  }
}
