import store from '@/store'
import { Plan } from '@/store/Plans'
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import axios from 'axios'
import config from '@/config'

export const ErrorMsg = {
  FetchLatestContractFailureError: '最新の契約を取得できませんでした',
  FetchFutureContractFailureError: '翌月以降の契約を取得できませんでした',
  FetchMaxGroupsFailureError: '上限グループ数を取得できませんでした',
} as const

export const PaymentStatus = {
  SETTLED: 'settled',
  UNSETTLED: 'unsettled',
  FAILED: 'failed',
} as const
export type PaymentStatusType = typeof PaymentStatus[keyof typeof PaymentStatus]

export const ContractType = {
  NEGOTIATION: 'negotiation',
  CREDIT: 'credit',
} as const
export type ContractTypeType = typeof ContractType[keyof typeof ContractType]

export type Contract = {
  id?: string
  organizationId: string
  planId: number
  paymentStatus: PaymentStatusType
  startDate: string
  endDate: string
  note?: string
  type: ContractTypeType
  trackingId?: string
  plan?: Plan
}

@Module({ dynamic: true, namespaced: true, name: 'contracts', store })
class Mod extends VuexModule {
  latestContract: Contract | null = null
  futureContract: Contract | null = null
  latestContractForNoContract: Contract | null = null
  futureContractForNoContract: Contract | null = null
  maxGroupsNoGroupCheck: number | null = 0

  @Mutation
  setLatestContract(latestContract: Contract): void {
    this.latestContract = latestContract
  }

  @Mutation
  setFutureContract(contract: Contract): void {
    this.futureContract = contract
  }

  @Mutation
  setLatestContractForNoContract(contract: Contract): void {
    this.latestContractForNoContract = contract
  }

  @Mutation
  setFutureContractForNoContract(contract: Contract): void {
    this.futureContractForNoContract = contract
  }

  @Mutation
  setMaxGroupsNoGroupCheck(maxGroups: number | null): void {
    this.maxGroupsNoGroupCheck = maxGroups
  }

  @Action({ commit: 'setLatestContract', rawError: true })
  async fetchLatestContract(): Promise<Contract> {
    const url = `${config.enterprise.apiUrl}/contracts/latest`
    try {
      const res = await axios.get(url)
      return Object.keys(res.data).length === 0 ? null : res.data
    } catch (e) {
      throw new Error(ErrorMsg.FetchLatestContractFailureError)
    }
  }

  @Action({ commit: 'setFutureContract', rawError: true })
  async fetchFutureContract(): Promise<Contract> {
    const url = `${config.enterprise.apiUrl}/contracts/future`
    try {
      const res = await axios.get(url)
      return Object.keys(res.data).length === 0 ? null : res.data
    } catch (e) {
      throw new Error(ErrorMsg.FetchFutureContractFailureError)
    }
  }

  @Action({ commit: 'setLatestContractForNoContract', rawError: true })
  async fetchLatestContractForNoContract(): Promise<Contract[]> {
    const url = `${config.enterprise.apiUrl}/no-contract/contracts/latest`
    try {
      const res = await axios.get(url)
      return res.data
    } catch (e) {
      throw new Error(ErrorMsg.FetchLatestContractFailureError)
    }
  }

  @Action({ commit: 'setFutureContractForNoContract', rawError: true })
  async fetchFutureContractForNoContract(): Promise<Contract[]> {
    const url = `${config.enterprise.apiUrl}/no-contract/contracts/future`
    try {
      const res = await axios.get(url)
      return res.data
    } catch (e) {
      throw new Error(ErrorMsg.FetchFutureContractFailureError)
    }
  }

  @Action({ commit: 'setMaxGroupsNoGroupCheck', rawError: true })
  async fetchMaxGroupsNoGroupCheck(): Promise<Contract[]> {
    const url = `${config.enterprise.apiUrl}/no-group-check/contracts/max-groups`
    try {
      const res = await axios.get(url)
      return res.data.maxGroups
    } catch (e) {
      throw new Error(ErrorMsg.FetchMaxGroupsFailureError)
    }
  }
}

export default getModule(Mod)
